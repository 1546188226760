@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

/* Utility Classes */
.gradient-input {
  background: linear-gradient(90deg, #1D1C1F 0%, #1C1C1E 25%, #272432 50%, #322B43 75%);
  color: white;
  border: none;
  padding: 1rem 2rem;
  height: 50px;
  margin: 0 5px;
  text-align: center;
  white-space: nowrap;
}

.pricing-card {
  background: linear-gradient(135deg, #191b24 0%, #282331 100%);
  color: white;
  margin: 0;
  padding: 70px;
  min-width: 400px;
  /* Adjust based on your desired width */
  min-height: 250px;
  /* Adjust based on your desired height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pricing-card ul {
  padding: 0;
  margin: 10px 0;
}

.pricing-card li {
  font-size: 20px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.pricing-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
}

.pricing-card button {
  background-color: white;
  color: black;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  width: 200px;
}

.pricing-card button:hover {
  background-color: #f0f0f0;
}

.pricing-card button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.old-price {
  color: red;
  text-decoration: line-through;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 176px;
  display: inline-block;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.83em;
}

h6 {
  font-size: 0.75em;
}

/* Components */

/* Header */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  padding: 15px 0;
  margin: 15px auto;
}

.title {
  background-image: linear-gradient(to left, #553c9a, #b393d3);
  color: transparent;
  background-clip: text;
  cursor: pointer;
}

/* Job List */
.jobList-main {
  width: 90%;
  margin: 0 auto;
}

.job-item {
  position: relative;
  list-style: none;
}

.job-description {
  width: 80%;
  text-align: center;
}

.custom-scroll-container {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #470726 #000;
}

.custom-scroll-container::-webkit-scrollbar {
  width: 10px;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

/* Login and Register */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.login-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 30px;
  text-align: center;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form label {
  width: 100%;
  margin: 0.5rem 0;
  color: #000;
  text-align: left;
  font-weight: bold;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
}

.login-message,
.message {
  color: red;
  margin-top: 1rem;
}

/* Payment Form */
.payment-form-container {
  width: 100%;
}

.payment-form {
  display: flex;
  flex-direction: column;
}

.pay-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 1rem;
  border-radius: 50px;
}

/* Insights */
.insights-container {
  margin-top: 4rem;
}

.title-disclaimer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.disclaimer {
  z-index: 999;
  width: min-content;
}

/* Media Queries */
@media (max-width: 768px) {
  .job-description h2 {
    font-size: 1.1rem;
  }

  .job-description p {
    font-size: 0.8rem;
  }

  .filter-form {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }

  .title {
    margin-top: 1rem;
    left: 0;
    transform: translateX(0);
  }

  .navbar {
    flex-direction: column;
  }

  .login-box {
    width: 95%;
  }
}

/* Additional Styles */

/* Buttons */
.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem 2rem;
  font-size: 1.15rem;
  border-radius: 50px;
}

.button.pay {
  margin-top: 28px;
}

/* Inputs */
input::placeholder {
  color: white;
  opacity: 1;
}

/* Blurred Job */
.blurred-job {
  filter: blur(5px);
  pointer-events: none;
}

.blurred-job h2 {
  filter: none;
  pointer-events: all;
}

/* Fixed Div */
.fixed {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Ensure Consistent Scrollbar Styling */
.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.basic-multi-select,
.basic-select {
  width: min-content
}

.disabled {
  color: gray;
  cursor: not-allowed;
  opacity: 0.5;
}

.arrow-container {
  display: flex;
  align-items: center;
  /* Alinea verticalmente el contenido */
  transition: transform 0.3s ease;
  /* Transición suave para el movimiento */
}

/* Animación al hacer hover */
.job-article:hover .arrow-container {
  animation: moveArrow 0.4s infinite alternate;
  /* Animación infinita hacia adelante y atrás */
}

.home-steps-button:hover .arrow-container {
  animation: moveArrow 0.4s infinite alternate;
  /* Animación infinita hacia adelante y atrás */
}

@keyframes moveArrow {
  0% {
    transform: translateX(0);
    /* Posición inicial */
  }

  100% {
    transform: translateX(7px);
    /* Mueve la flecha hacia la derecha */
  }
}

.home-title {
  font-size: 220px;
  font-weight: bold;
}

.home-title span {
  font-size: 135px;
  font-weight: normal;
}

.home__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding: 0 8px;
}

/* Estilo base para cada tarjeta */
.home__card {
  width: 24%;
  padding: 8px;
  transition: transform 0.3s ease;
}

@media (max-width: 1100px) {
  .home__card {
    transform: scale(0.8);
  }
}

@media (max-width: 860px) {
  .home__card {
    transform: scale(0.70);
  }
}


@media (max-width: 780px) {
  .home__card {
    transform: scale(0.65);


  }
}

@media (max-width: 735px) {
  .home__card {
    transform: scale(0.50);
  }
}


@media (max-width: 600px) {
  .home__card {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    button {
      scale: 1.7;
    }
  }
}


@media (max-width: 1300px) {
  .home-title {
    font-size: 10rem;
  }

  .home-description {
    font-size: 1.2rem;
  }

  .home-title span {
    font-size: 5.2rem;
  }
}

.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

@media (max-width: 1045px) {
  .home-container {
    flex-direction: column;
    align-items: center;
  }

  .home-title {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .home-title span {
    position: relative;
    top: -60px;
  }
}

.home__content {
  display: flex;
  align-items: center;
}

@media (max-width: 1045px) {
  .home__content {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
}

.home-description {
  font-size: 1.3rem;
}

@media (max-width: 768px) {
  .home-description {
    font-size: 1rem;
  }
}